<template>
    <div class="mt-4">
        <apply />
    </div>
</template>

<script>
import Apply from '../components/Apply.vue';

export default {
    components: {
        Apply
    }
};
</script>

<style lang="scss" scoped></style>
